<template>
  <div>
    <!-- 공지사항 제목 -->
    <div class="info-container">
      <!-- 제목 -->
      <div class="info-header-wrap">
        <div class="flex-align-center">
          <div
            class="notice-accent"
            style="margin-right: 13px"
            v-if="noticeData.top"
          >
            중요
          </div>
          <span class="notice-title" style="margin-right: 5px" v-else
            >[일반]</span
          >
          <span class="notice-title">{{ noticeData.title }}</span>
        </div>
        <span class="notice-title">{{ formatDate(noticeData.eDate) }}</span>
      </div>
      <!-- 컨텐츠 -->
      <div class="info-content-wrap">
        <div v-html="noticeData.content" />
      </div>
      <div class="info-button-wrap">
        <button-common :size="SIZE_T" :clr="CLR_W" @click="goNoticeCompanyList"
          >목록보기</button-common
        >
        <div class="flex">
          <button-common
            :size="SIZE_T"
            :clr="CLR_W"
            style="margin-right: 8px; color: #f1431d"
            @click="requestDelete"
            >삭제</button-common
          >
          <button-common :size="SIZE_T" :clr="CLR_M" @click="goNoticeEdit"
            >수정</button-common
          >
        </div>
      </div>
    </div>
    <div class="info-container">
      <!-- 이전글 -->
      <div
        class="info-header-wrap"
        style="cursor: pointer"
        @click="goNoticeDetail(prevData.esntlId)"
        v-if="prevData"
      >
        <div class="flex-align-center">
          <img src="@/assets/image/arrow_up.png" class="notice-arrow" />
          <span class="notice-prev-next">이전글</span>
          <div
            class="notice-accent"
            style="margin-right: 13px"
            v-if="prevData.top === 'true'"
          >
            중요
          </div>
          <span class="notice-title" style="margin-right: 5px" v-else
            >[일반]</span
          >
          <span class="notice-title">{{ prevData.title }}</span>
        </div>
        <span class="notice-title">{{ formatDate(prevData.eDate) }}</span>
      </div>
      <!-- 다음글 -->
      <div
        class="info-header-wrap"
        style="cursor: pointer"
        @click="goNoticeDetail(nextData.esntlId)"
        v-if="nextData"
      >
        <div class="flex-align-center">
          <img src="@/assets/image/arrow_down.png" class="notice-arrow" />
          <span class="notice-prev-next">다음글</span>
          <div
            class="notice-accent"
            style="margin-right: 13px"
            v-if="nextData.top === 'true'"
          >
            중요
          </div>
          <span class="notice-title" style="margin-right: 5px" v-else
            >[일반]</span
          >
          <span class="notice-title">{{ nextData.title }}</span>
        </div>
        <span class="notice-title">{{ formatDate(nextData.eDate) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NoticeCompanyDetail",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      noticeData: {
        esntlId: "",
        title: "",
        content: "",
        eDate: "",
        top: false,
      },
      prevData: null,
      nextData: null,
    };
  },

  /*** created ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    if (this.$route.params.id) {
      await this.getNoticeData();
    }
  },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {
    $route(to, from) {
      if (this.$route.params.id) {
        this.getNoticeData();
      }
    },
  },

  /*** methods ***/
  methods: {
    /** @ 공지사항 상세 조회 ***/
    async getNoticeData() {
      const rs = await this.$axios({
        url: "/admin/notice/partner/data",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.noticeData = rs.resultData;
        this.noticeData.top = this.noticeData.top === "true";
        this.prevData = rs.prevData ? rs.prevData : null
        this.nextData = rs.nextData ? rs.nextData : null
      } else {
        console.log("공지사항 조회 실패");
      }
    },

    formatDate(date) {
      return this.$moment(date).format("YYYY.MM.DD");
    },

    goNoticeDetail(id) {
      this.$router.push({
        path: `/notice/company/detail/` + id,
        meta: { id: "15-1-1-1" },
      });
    },

    goNoticeEdit() {
      this.$router.push({
        path: `/notice/company/add/` + this.noticeData.esntlId,
        meta: { id: "15-1-1-2" },
      });
    },

    async requestDelete() {
      await this.$openConfirm(
        {
          bodyTx: "게시물을 삭제 하시겠습니까?",
          btnScndNm: "취소",
          btnPrmyNm: "삭제",
          cbPrmy: this.deleteNoticeData,
          data: {},
        },
        this
      );
    },

    /** @ 공지사항 삭제 ***/
    async deleteNoticeData() {
      let deleteList = [];
      deleteList.push({ esntlId: this.noticeData.esntlId });
      const rs = await this.$axios({
        url: "/admin/notice/partner/delete",
        params: { deleteList: deleteList },
      });
      if (rs.result === "SUC") {
        this.goNoticeCompanyList();
      } else {
        console.log("공지사항 삭제 실패");
      }
    },

    goNoticeCompanyList() {
      this.$router.push({
        path: `/notice/company/list`,
        meta: { id: "15-1-1" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.info-content-wrap {
  padding: 16px 194px;
  border-bottom: 1px solid #d8dae6;
}
.info-button-wrap {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
}
.notice-accent {
  color: #f1431d;
  border: 1px solid #f1431d;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
}
.notice-title {
  color: #515466;
  font-weight: 500;
  font-size: 14px;
}
.notice-arrow {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.notice-prev-next {
  font-size: 12px;
  color: #828699;
  margin-right: 32px;
}
</style>
